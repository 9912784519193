import Vue from 'vue';
import Vuex from 'vuex';
import { UserRole } from '@/constants';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    ),
    userInfo: {}
  },
  // 废弃，不确定哪里出了问题，貌似登录后没有更新下面逻辑
  getters: {
    userInfo: (state) => {
      return state.userInfo;
    },
    isAdmin: (state) => {
      console.log('userRole is ' + state.userInfo.userRole);
      return (
        state.userInfo.userRole === UserRole.ADMIN ||
        state.userInfo.userRole === UserRole.SYSTEM_HIVE
      );
    },
    isAgent: (state) => state.userInfo.userRole === UserRole.AGENT
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    }
  }
});

export default store;
